
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from './ThreeBoxes.module.scss';
import arrow from "../../images/chevron.svg"
import { createLink } from "../../utils"

export const fragment = graphql`
  fragment threeBoxesFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_ThreeBoxes {  
        firstTitle
        firstParagraph
        firstIcon {
           ...GatsbyImageQuery
        }
        firstSource {
          title
          url
        }
        boxes {
            title
            paragraph
            icon {
               ...GatsbyImageQuery
            }
            linkTo {
              title
              url
            }
          }
}
`
export const ThreeBoxes = ({firstTitle,firstParagraph,firstIcon,firstSource,boxes}) => {

  return (
    <section className="p-0 mb-2 mb-lg-5">
      <Container>
        <Row className={`mb-4 flex-column flex-md-row ${style.firstRow}`}>
          <Col md={6} className="px-md-0">
            <div className={` ${style.box} `}>
              <div
                className={`position-relative  py-5 ${style.bgBox} ${style.bgRed}`}
              >
                <img src={firstIcon.sourceUrl} alt="right arrow" className="" />
                <h3
                  className={` text-center text-white mt-3 `}
                  dangerouslySetInnerHTML={{ __html: firstTitle }}
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="px-md-0">
            <div className={` h-100 pt-5 pb-4 px-4 ${style.redBox} `}>
              <h5
                className={`${style.text} text-left font-weight-normal `}
                dangerouslySetInnerHTML={{ __html: firstParagraph }}
              />
              <a className={style.link} href={createLink(firstSource.url)}>
                <h5 className={`font-weight-bold  text-left ${style.linkText}`}>
                  {firstSource.title}{" "}
                </h5>
              </a>
            </div>
          </Col>
        </Row>

        <Row className={style.row}>
          {boxes.map((item, i) => {
            return (
              <Col xs={12} md={6} key={i} className="mx-auto mb-4">
                {(function() {
                  switch (i) {
                    case 0:
                      return (
                        <div className={`card  ${style.box} `}>
                          <div
                            className={`position-relative  py-5 ${style.bgBox} ${style.bgBlue}`}
                          >
                            <img
                              src={item.icon.sourceUrl}
                              alt="right arrow"
                              className=""
                            />
                            <h3
                              className={` text-center text-white mt-3 `}
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            />
                          </div>
                          <div
                            className={`card-body h-100 pt-5 pb-4 ${style.blueBox} `}
                          >
                            <h5
                              className={`${style.text} text-center font-weight-normal `}
                              dangerouslySetInnerHTML={{
                                __html: item.paragraph,
                              }}
                            />
                            <a
                              className={style.link}
                              href={createLink(item.linkTo.url)}
                            >
                              <h5
                                className={`font-weight-bold ${style.linkText}`}
                              >
                                {item.linkTo.title}{" "}
                              </h5>
                            </a>
                          </div>
                        </div>
                      )
                      break
                    case 1:
                      return (
                        <div className={`card ${style.box} `}>
                          <div
                            className={`position-relative py-5  ${style.bgBox} ${style.bgGreen}`}
                          >
                            <img
                              src={item.icon.sourceUrl}
                              alt="right arrow"
                              className=""
                            />
                            <h3
                              className={` text-center text-white mt-3`}
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            />
                          </div>
                          <div
                            className={`card-body h-100 pt-5 pb-4  ${style.greenBox} `}
                          >
                            <h5
                              className={` text-center  font-weight-normal ${style.text} `}
                              dangerouslySetInnerHTML={{
                                __html: item.paragraph,
                              }}
                            />
                            <a
                              className={style.link}
                              href={createLink(item.linkTo.url)}
                            >
                              <h5
                                className={`font-weight-bold ${style.linkText}`}
                              >
                                {item.linkTo.title}{" "}
                              </h5>
                            </a>
                          </div>
                        </div>
                      )
                      break
                    default:
                      return <div></div>
                  }
                })()}
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}
