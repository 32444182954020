import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import style from "./HeaderImgagesAndBtn.module.scss"
import { LinkButton } from "../LinkButton"

export const fragment = graphql`
         fragment headerOneImgAndBtnFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_HeaderOneImgAndBtn {
           paragraph
           sectionHeader
           buttonIcon {
             ...GatsbyImageQuery
           }
           image {
             ...GatsbyImageQuery
           }
           button {
             title
             url
           }
         }
       `

// styles from HeaderImgagesAndBt.module.scss  !!!!!

export const HeaderOneImgAndBtn = ({
  sectionHeader,
  paragraph,
  image,
  button,
}) => {
  return (
    <div className={` my-5 ${style.mainSection}`}>
      <Container fluid={true}>
        <h3
          className={`${style.textColor} mb-4`}
          dangerouslySetInnerHTML={{ __html: sectionHeader }}
        />
        <div
          className={`font-weight-normal mb-5 ${style.text}`}
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
        {image && (
          <img
            src={image.sourceUrl}
            alt={image.title}
            className={`w-100 mb-3 ${style.mainImg}`}
          />
        )}

        {button ? (
          <div className="d-flex justify-content-center mb-7 mt-5">
            <LinkButton href={button.url} title={button.title} />
          </div>
        ) : null}
      </Container>
    </div>
  )
}
