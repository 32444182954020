import { graphql } from "gatsby"
import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import BackgroundImage from "gatsby-background-image"
import style from "./TextBoxOnFullWidthImg.module.scss"
import { LinkButton } from "../LinkButton"

export const fragment = graphql`
  fragment textBoxOnFullWidthImgFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_TextBoxOnFullWidthBkgdImg {
    text
    backgroundImage {
      altText
      ...GatsbyImageQuery
      id
    }
    button {
      title
      url
    }
  }
`

export const TextBoxOnFullWidthImg = ({ text, backgroundImage, button }) => {
  return (
    <>
      <div id="bgImg">
        <BackgroundImage
          fluid={backgroundImage.imageFile.childImageSharp.fluid}
          id={style.backgroundImage}
        >
          <Container className={`${style.container} pt-7 pb-5`}>
            <Row
              noGutters={true}
              className={"mt-4 pb-4 px-lg-9 mt-lg-11 pb-lg-7"}
            >
              <Col md={4} lg={5} />
              <Col xs={12} md={7} lg={6}>
                <div
                  className={`${style.panal} d-flex flex-column justify-content-between align-items-start`}
                >
                  <div className={style.textBox}>
                    <p className={"h4 text-left font-weight-normal"} style={{ color: `#201b38`}}>
                      {text}
                    </p>
                  </div>
                  {button ? (
                    <LinkButton
                      href={button.url}
                      title={button.title}
                      className="font-weight-bold"
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>
      </div>
    </>
  )
}
