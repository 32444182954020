import { graphql } from "gatsby"
import React, { useRef, useState } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { GatsbyImage } from "../GatsbyImage"
import style from "./StatsSlider.module.scss"
import leftArrow from "../../images/chevron-left.png"
import rightArrow from "../../images/chevron-right.png"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { Link } from "../Link"

export const fragment = graphql`
  fragment statsSliderFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_FeaturedStatsSlider {
    heading
    slideContent {
      description
      freaturedStatNumber
      freaturedStatText
      icon {
        altText
        ...GatsbyImageQuery
        id
      }
      iconPosition
      slideLink
      slideColor
    }
  }
`

const SlideContent = ({
  iconPosition,
  icon,
  description,
  freaturedStatNumber,
  freaturedStatText,
}) => (
  <Row>
    <div className="col-xs-12 col-md-5 col-xxl-6">
      <div
        className={`${
          style.imgWrapper
        } d-flex flex-column justify-content-center align-items-center ${iconPosition ===
          "center" && "justify-content-sm-center"} ${iconPosition ===
          "bottom" && "justify-content-sm-end"}`}
      >
        <GatsbyImage img={icon} className={`${style.slideImage} w-100`} />
      </div>
    </div>
    <div className="col-xs-12 col-md-7 col-xxl-6">
      <div
        className={`${style.textWrapper} d-flex flex-column align-items-center align-items-sm-start justify-content-center p-2`}
      >
        <p style={{ textAlign: "left" }} className="h6">
          {description}
        </p>
        {freaturedStatNumber && (
          <p
            style={{
              textAlign: "left",
            }}
            className="h1"
          >
            {freaturedStatNumber}
          </p>
        )}
        {freaturedStatText && (
          <p className={`${style.textColor} h3 pb-3 pb-sm-0 text-sm-left`}>{freaturedStatText}</p>
        )}
      </div>
    </div>
  </Row>
)

export const StatsSlider = ({ heading, slideContent }) => {
  const slider = useRef(null)

  const [settings, setSettings] = useState({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          variableWidth: false,
          centerMode: true,
        },
      },
    ],
  })

  return (
    <div className={`mt-7 mb-9 ${style.textColor}`}>
      <h2 className={`${style.textColor}`} >{heading}</h2>
      <Slider
        className={"pt-4 pb-4 pb-sm-5 pt-lg-6 pb-lg-7"}
        ref={slider}
        {...settings}
      >
        {slideContent.map((item, i) => {
          return (
            <div
              key={`stats-slide${i}`}
              className={`${style.slideItem} d-block mx-2 `}
            >
              <div
                className={style.slideBorder}
                style={{ border: `${item.slideColor} solid 16px` }}
              >
                <div className="p-3">
                  {item.slideLink ? (
                    <Link href={item.slideLink} className={style.link}>
                      <SlideContent {...item} />
                    </Link>
                  ) : (
                    <SlideContent {...item} />
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
      <p className={`${style.scroller} text-center`}>
        <Button variant="secondary" onClick={() => slider.current.slickPrev()}>
          <img src={leftArrow} alt={"previous"} className={"pr-2 pl-1"} />
        </Button>
        <Button variant="secondary" onClick={() => slider.current.slickNext()}>
          <img src={rightArrow} alt={"next"} className={"pl-2"} />
        </Button>
      </p>
    </div>
  )
}
