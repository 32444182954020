
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from './HeaderInWhiteBox.module.scss';
import BackgroundImage from "gatsby-background-image"


export const fragment = graphql`
  fragment headerInWhiteBoxFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_HeaderInWhiteBox {  
    sectionHeader
    paragraph
    backgroundImg {
      ...GatsbyImageQuery
    }
    services {
      servicesText
      servicesTitle
    }
     
  }
`

export const HeaderInWhiteBox = ({sectionHeader,paragraph,backgroundImg,services}) => {

  return (
    <div className="section">
      <BackgroundImage
        fluid={backgroundImg.imageFile.childImageSharp.fluid} className="bg"
      >
          <section  >
            <Container fluid={true} className={style.mainContainer}>
              <Row >
                <Col sm={12}>
                  <h2 className={`mb-4 ${style.title}`} dangerouslySetInnerHTML={{ __html: sectionHeader }} />
                  {paragraph && <p  className={style.text} dangerouslySetInnerHTML={{ __html: paragraph }} />}
                </Col>
              </Row>

              <Row className={style.listRow}>
                <Col className={style.listCol}>

                      {
                       services && services.map((item, i) => {
                           return (
                               <Col sm={12}  key={i}>
                                  <ul className={style.list}>
                                      <li>
                                         <h5  className={` font-weight-bold ${style.listText}`} dangerouslySetInnerHTML={{ __html: item.servicesTitle }} />
                                         <h5  className={style.listText} dangerouslySetInnerHTML={{ __html: item.servicesText }} />
                                      </li>
                                  </ul>
                                </Col>
                              )
                         })
                     }

                </Col>
              </Row>
            </Container>
          </section>
      </BackgroundImage>
    </div>
  )
}

