
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import BackgroundImage from "gatsby-background-image"
import { LinkButton } from "../LinkButton"

export const fragment = graphql`
         fragment leftImageFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_LeftImage {
           title
           paragraph
           backgroundImage {
             ...GatsbyImageQuery
           }
           button {
             title
             url
           }
           image {
             ...GatsbyImageQuery
           }
         }
       `
export const LeftImage = ({
  title,
  paragraph,
  button,
  image,
  backgroundImage,
}) => {
  return (
    <BackgroundImage
      fluid={backgroundImage.imageFile.childImageSharp.fluid}
      className="bg"
    >
      <section className="p-0 mb-2 mb-lg-5">
        <Container>
          <Row className={`py-4 flex-column flex-md-row rowContainer`}>
            <Col md={6} className="px-md-0">
              <div>
                <div className={`position-relative  py-5 `}>
                  <img
                    src={image.sourceUrl}
                    className="leftImg"
                    alt="right arrow"
                  />
                </div>
              </div>
            </Col>
            <Col md={6} className="px-md-0 ">
              <div className={` h-100 pt-4`}>
                {title && (
                  <h2
                    className={`text-left pt-1 mb-4`}
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                )}
                <h5
                  className={` text-left font-weight-normal `}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                  style={{ fontSize: "20px" }}
                />

                {button ? (
                  <div className="my-5">
                    <LinkButton
                      href={button.url}
                      title={button.title}
                      arrowIcon
                    />
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </BackgroundImage>
  )
}
