import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from "./WhiteBoxWithBgimg.module.scss"
import BackgroundImage from "gatsby-background-image"
import { LinkButton } from "../LinkButton"

export const fragment = graphql`
         fragment whiteBoxWithBgimgFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_WhiteBoxWithBgimg {
           sectionHeader
           paragraph
           bottomParagraph
           backgroundImage {
             ...GatsbyImageQuery
           }
           button {
             title
             url
           }
           items {
             itemTitle
             itemText
           }
         }
       `

export const WhiteBoxWithBgimg = ({
  sectionHeader,
  paragraph,
  backgroundImage,
  bottomParagraph,
  button,
  items,
}) => {
  return (
    <>
      <BackgroundImage
        fluid={backgroundImage.imageFile.childImageSharp.fluid}
        className="bg"
      >
        <section>
          <Container fluid={true} className={style.mainContainer}>
            <Row>
              <Col sm={12}>
                <h2
                  className={`mb-4 ${style.title}`}
                  dangerouslySetInnerHTML={{ __html: sectionHeader }}
                />
                {paragraph && (
                  <div
                    className={style.text}
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                  />
                )}
              </Col>
            </Row>

            <Row className={style.listRow}>
              <Col>
                {items &&
                  items.map((item, i) => {
                    return (
                      <Col sm={12} key={i} className={style.listCol}>
                        <ul className={style.list}>
                          <li>
                            {item && (
                              <h5
                                className={` font-weight-bold ${style.listText}`}
                                dangerouslySetInnerHTML={{
                                  __html: item.itemTitle,
                                }}
                              />
                            )}
                            {item && (
                              <div
                                className={style.listText}
                                dangerouslySetInnerHTML={{
                                  __html: item.itemText,
                                }}
                              />
                            )}
                          </li>
                        </ul>
                      </Col>
                    )
                  })}
              </Col>
            </Row>
            {bottomParagraph && (
              <div
                className={style.text}
                dangerouslySetInnerHTML={{ __html: bottomParagraph }}
              />
            )}

            {button ? (
              <div className="d-flex justify-content-center my-5">
                <LinkButton href={button.url} title={button.title} arrowIcon />
              </div>
            ) : null}
          </Container>
        </section>
      </BackgroundImage>
    </>
  )
}
