
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from './TipsForDonating.module.scss';

export const fragment = graphql`
  fragment tipsForDonatingFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_TipsForDonating {  
      sectionHeader
      tips {
        icon {
       ...GatsbyImageQuery
        }
        title
        tips {
          tipsText
        }
      }
      noteTitle
      paragraph
      notes {
        noteText
        video {
          title
          url
        }
        pdf {
          title
          url
        }
       
      }  
  }
`

export const TipsForDonating = ({sectionHeader,tips,noteTitle,paragraph,notes}) => {

  return (
    <div className="section">
        <section >
          <h1 className={`h3 mb-4 ${style.title}`} dangerouslySetInnerHTML={{ __html: sectionHeader }} />
          <Row  className={style.row}>
              {
                tips.map((item, i) => {
                    return (
                      <Col sm={12} md={6}  key={i} className="mb-2" >
                        <div className={style.box}>
                          <div className="d-flex justify-content-center mt-4 mb-3 ">
                               <img src={item.icon.sourceUrl} alt="chevron"/>
                          </div>
                          <h3 className={` mb-4 text-center ${style.listName}`} dangerouslySetInnerHTML={{ __html: item.title }} />
                          <ul className={style.list}>
                            {item.tips.map((tip, i) => {
                              return (
                                <>
                                <li key={i} className="mb-2">
                                  <div className={`font-weight-normal text-left ${style.tipParagraph}`} dangerouslySetInnerHTML={{ __html: tip.tipsText }} />
                                </li>
                                </>
                              )
                            })}
                          </ul>
                        </div>
                      </Col>
                    )
                })
              }
          </Row>

            <Row  className={style.secondRow}>
              <div className={style.secondBox}>
                  <h3 className={` mb-4  mt-4 text-center ${style.listName}`} dangerouslySetInnerHTML={{ __html: noteTitle }} />
                  <h5 className={`px-3  pb-3 font-weight-normal text-left ${style.paragraphText}`} dangerouslySetInnerHTML={{ __html: paragraph }} />
                    {
                      notes.map((item,i) => {
                        return (
                            <Col sm={12} key={i}>
                                <ul className={style.secondList}>
                                      <li>
                                        <div className={`font-weight-bold text-left ${style.noteParagraph}`} dangerouslySetInnerHTML={{ __html: item.noteText }} />
                                        {item.video &&  <a href={item.video.url} className={style.redLink} target="_blank"><span className={style.redLink}>{item.video.title}</span></a>}
                                        {item.video && item.pdf && <span className={style.redLink}> | </span>}
                                        {item.pdf &&  <a href={item.pdf.url} className={`${style.redLink}`} target="_blank"><span className={`${style.redLink}`}>{item.pdf.title}</span></a>}
                                      </li>
                                  </ul>
                            </Col>
                        )
                      })
                    }
              </div>
            </Row>

        </section>

    </div>
  )
}

