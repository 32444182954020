import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from "./HeaderImgagesAndBtn.module.scss"
import { LinkButton } from "../LinkButton"

export const fragment = graphql`
  fragment headerImgagesAndBtnFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_HeaderImgagesAndBtn {
    paragraph
    sectionHeader
    buttonIcon {
      ...GatsbyImageQuery
    }
    twoImages {
      image {
        ...GatsbyImageQuery
      }
    }
    button {
      title
      url
    }
  }
`

export const HeaderImgagesAndBtn = ({
  sectionHeader,
  paragraph,
  twoImages,
  button,
}) => {
  return (
    <section className="section">
      <Container fluid={true}>
        <h2
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: sectionHeader }}
        />
        {paragraph && (
          <div
            className={`font-weight-normal ${style.text}`}
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        )}
        <Row className={` my-5 ${style.row}`}>
          {twoImages.map((item, i) => {
            return (
              <Col sm={12} md={6} key={i}>
                {item.image && (
                  <img
                    src={item.image.sourceUrl}
                    alt={item.image.title}
                    className="w-100 mb-3"
                  />
                )}
              </Col>
            )
          })}
        </Row>
        {button ? (
          <div className="d-flex justify-content-center">
            <LinkButton href={button.url} title={button.title} />
          </div>
        ) : null}
      </Container>
    </section>
  )
}
