import { graphql } from "gatsby"
import React, { useRef, useState } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from "./QuoteSlider.module.scss"
import Button from "react-bootstrap/Button"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import openQuote from "../../images/quote.svg"
import closeQuote from "../../images/quote-2.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

export const fragment = graphql`
  fragment quoteSliderFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_QuoteSlider {
    sectionHeading
    sectionColour
    quotes {
      quoteAuthor
      quoteText
    }
  }
`

export const QuoteSlider = ({ sectionHeading, sectionColour, quotes }) => {
  const slider = useRef(null)
  const [slideIndex, setSlideIndex] = useState(0)

  const NextArrow = () => (
    <Button
      className={style.nextArrow}
      variant="link"
      onClick={() => slider.current.slickNext()}
    >
      <FontAwesomeIcon
        size="2x"
        icon={faChevronRight}
        className="text-secondary"
      />
    </Button>
  )

  const PrevArrow = () => (
    <Button
      className={style.prevArrow}
      variant="link"
      onClick={() => slider.current.slickPrev()}
    >
      <FontAwesomeIcon
        size="2x"
        icon={faChevronLeft}
        className="text-secondary"
      />
    </Button>
  )

  const settings = {
    appendDots: dots => {
      return (
        <div>
          <ul>{dots}</ul>
        </div>
      )
    },
    dots: true,
    dotsClass: `${style.sliderDots} quote-slider`,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setSlideIndex(next),
  }

  return (
    <div className="py-5 py-lg-7" style={{ backgroundColor: sectionColour }}>
      <h2 className="mb-4">{sectionHeading}</h2>
      <Slider ref={slider} {...settings}>
        {quotes.map((item, index) => (
          <div
            key={`quote-slide-${index}`}
            className="d-flex justify-content-center"
          >
            <Col
              xs={11}
              md={9}
              lg={8}
              xl={7}
              style={{
                backgroundColor: `${sectionColour}`,
                border: "white solid 8px",
              }}
              className={`${style.textBox} col-xxl-6 my-5 p-4 py-md-5 p-lg-7`}
            >
              <img
                src={openQuote}
                alt={"Open quote mark"}
                className={style.openQuote}
              />
              <Row noGutters={true}>
                <div
                  className={`ml-md-5 ml-lg-0 d-flex flex-column align-items-center justify-content-center h-100`}
                >
                  <div
                    className={`${style.innerTextBox} d-flex flex-column align-content-start justify-content-center`}
                  >
                    <p className={`${style.text} h5 text-left`}>
                      {item.quoteText}
                    </p>
                    <p className={`${style.author} h6 text-left pt-3`}>
                      {`– ${item.quoteAuthor}`}
                    </p>
                  </div>
                </div>
              </Row>
              <img
                src={closeQuote}
                alt={"Close quote mark"}
                className={style.closeQuote}
              />
            </Col>
          </div>
        ))}
      </Slider>
    </div>
  )
}
