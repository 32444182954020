import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from "./Wishlist.module.scss"
import BackgroundImage from "gatsby-background-image"
import { LinkButton } from "../LinkButton"

export const fragment = graphql`
  fragment wishlistFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_Wishlist {
    backgroundImage {
      ...GatsbyImageQuery
    }
    packs {
      button {
        title
        url
      }
      icon {
        ...GatsbyImageQuery
      }
      title
      price
      image {
        ...GatsbyImageQuery
      }
      objects {
        objectTitle
      }
    }
  }
`

export const Wishlist = ({ packs, backgroundImage }) => {
  return (
    <div className="section  mb-lg-0">
      <BackgroundImage
        fluid={backgroundImage.imageFile.childImageSharp.fluid}
        className="bg"
      >
        <section>
          <Container fluid={true}>
            <Row className={style.listRow}>
              {packs.map((item, i) => {
                const { icon, title, price, image, objects, button } = item

                return (
                  <div className={style.mainContainer}>
                    <Col sm={12} lg={4} xl={4} key={i}>
                      <img src={image.sourceUrl} alt={image.title} />
                    </Col>

                    <Col sm={12} lg={8} xl={8} className="position-relative">
                      <div
                        className={`d-flex align-items-center justify-content-between ${style.textRow}`}
                      >
                        <h3
                          className="pl-3"
                          dangerouslySetInnerHTML={{ __html: title }}
                        />
                        <div
                          className={`position-relative text-center ${style.starIcon}`}
                        >
                          <img src={icon.sourceUrl} alt={icon.title} />
                          <h5
                            className={`position-absolute ${style.priceText}`}
                            dangerouslySetInnerHTML={{ __html: price }}
                          />
                        </div>
                      </div>

                      <ul className={style.list}>
                        {objects.map((tip, i) => {
                          return (
                            <>
                              <li key={i}>
                                <h5
                                  className={`text-left font-weight-normal pl-3 ${style.text}`}
                                  dangerouslySetInnerHTML={{
                                    __html: tip.objectTitle,
                                  }}
                                />
                              </li>
                            </>
                          )
                        })}
                      </ul>

                      {button ? (
                        <div className="d-flex justify-content-center my-5">
                          <LinkButton
                            href={button.url}
                            title={button.title}
                            arrowIcon
                          />
                        </div>
                      ) : null}
                    </Col>
                  </div>
                )
              })}
            </Row>
          </Container>
        </section>
      </BackgroundImage>
    </div>
  )
}
