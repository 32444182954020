
import { graphql } from "gatsby"
import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from './ShopBanner.module.scss';


export const fragment = graphql`
  fragment shopBannerFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_ShopBanner {  
      sectionHeader
      paragraph
      leftImage {
        ...GatsbyImageQuery
      }
      rightImage {
          ...GatsbyImageQuery
      } 
}
`
export const ShopBanner = ({sectionHeader,paragraph,leftImage,rightImage}) => {

  return (
    <>
      <section className={`p-0 mb-4 mb-lg-5 pb-lg-4 ${style.section}`} >
          <div className={`d-flex flex-column position-relative px-sm-5`}>
            <img src={leftImage.sourceUrl}  alt="bear"  className={style.leftImg} />
            <div className={`d-flex flex-column order-1 order-lg-2 ${style.textDiv}`}>
              <h1 className={`text-center text-white mb-4 mt-lg-6 h2 ${style.title}`} dangerouslySetInnerHTML={{ __html: sectionHeader }} />
              <h5 className={` text-center font-weight-bold ${style.text}`} dangerouslySetInnerHTML={{ __html: paragraph }}/>
            </div>
            <img src={rightImage.sourceUrl}  className={style.rightImg} alt="fox"  />
          </div>
      </section>

    <section className={`p-0 mb-4 mb-lg-5 pb-lg-4 ${style.laptopSection}`} >
      <div className={`d-flex flex-column position-relative px-sm-5`}>
        <Row>
            <Col lg={3}>
              <img src={leftImage.sourceUrl}  alt="bear"  className={style.leftImg} />
            </Col>
            <Col lg={6} className="d-flex">
              <div className={`d-flex flex-column order-1 order-lg-2 ${style.textDiv}`}>
                <h1 className={`text-center text-white mt-4 mb-4 mt-lg-6 h2`} dangerouslySetInnerHTML={{ __html: sectionHeader }} />
                <h5 className={` text-center font-weight-bold ${style.text}`} dangerouslySetInnerHTML={{ __html: paragraph }}/>
              </div>
            </Col>
            <Col lg={3}>
                <img src={rightImage.sourceUrl}  className={style.rightImg} alt="fox"  />
            </Col>
        </Row>
      </div>

      </section>
</>
  )
}
