import React, { useState, Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from "./ContactPage.module.scss"
import axios from "axios"
import queryString from "query-string"
import redArrow from "../../images/chevron-red.svg"
import { GatsbySrcImage } from "../GatsbyImage"
import arrow from "../../images/chevron.svg"
import { BtnLoader } from "../UI/Loader"
import { Alert, Card, Form, Button } from "react-bootstrap"
import { createLink } from "../../utils"
export const fragment = graphql`
  fragment contactPageFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_ContactPage {
    title
    infoLinks {
      infoLink {
        title
        url
      }
    }
    infoLinkDescription
    formDescription
    tcLink
    submitMessage
  }
`
const getData = graphql`
  {
    heart: file(relativePath: { eq: "baby-bib.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const ContactPage = ({
  title,
  infoLinks,
  infoLinkDescription,
  formDescription,
  tcLink,
  submitMessage,
  view,
}) => {
  const [isSubmitting, setIsSubmitting] = useState()
  const [status, setStatus] = useState({
    initial: true,
  })
  const [selectedEnquiry, setSelectedEnquiry] = useState("")
  const [isDropdownListOpen, setIsDropdownListOpen] = useState(false)
  const enquiryOptions = [
    {
      name: "Financial donation or fundraising",
      url: process.env.CONTACT_FORM_FINANCIAL_DONATION,
    },
    { name: "Volunteering", url: process.env.CONTACT_FORM_VOLUNTEERING },
    {
      name: "Getting a referral, or you've made a referral",
      url: process.env.CONTACT_FORM_REFFERALS,
    },
    { name: "General enquiry", url: process.env.CONTACT_FORM_GENERAL_ENQUIRY },
  ]
  let FormURL =
    selectedEnquiry !== "" &&
    enquiryOptions.filter(enquiry => enquiry.name === selectedEnquiry)

  const data = useStaticQuery(getData)

  const [btnDisabled, setbtnDisabled] = useState(true)

  const validate = event => {
    const form = event.currentTarget
    if (form.checkValidity() === true) {
      event.stopPropagation()
      setbtnDisabled(false)
    } else {
      event.stopPropagation()
      setbtnDisabled(true)
    }
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    const data = {
      firstName: form.elements.firstName.value,
      lastName: form.elements.lastName.value,
      email: form.elements.email.value,
      phone: form.elements.phone.value,
      comment: form.elements.comment.value,
    }

    const postForm = async () => {
      try {
        setIsSubmitting(true)
        const response = await axios.post(
          `${FormURL[0].url}`,
          queryString.stringify(data)
        )

        setStatus({
          success: submitMessage,
          initial: false,
        })
      } catch (exception) {
        setIsSubmitting(false)
        console.error(exception)
        setStatus({
          ...status,
          exception: `${exception.response.status}: ${exception.response.statusText}`,
        })
      }
    }
    postForm()
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0)
    }
  }

  return (
    <section className={style.section}>
      {status && status.initial && (
        <Fragment>
          <div className="position-relative">
            <Container>
              <Row className="justify-content-center">
                <Col lg={10} xl={8}>
                  <h1
                    className={`${style.textColor} mt-5`}
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                  <p
                    className={`${style.textColor} pt-5 pb-3 h5 text-left`}
                    dangerouslySetInnerHTML={{
                      __html: infoLinkDescription,
                    }}
                  />
                  <Row className="text-secondary">
                    {infoLinks.map((item, i) => {
                      return (
                        <Col xs={12} sm={6} key={i} className="py-2">
                          <p>
                            {" "}
                            <img
                              className={`pb-1 pr-2`}
                              src={redArrow}
                              alt="arrow"
                            />{" "}
                            <a
                              className={`${style.textColor} text-secondary h6 text-left`}
                              href={createLink(item.infoLink.url)}
                            >
                              {item.infoLink.title.toLowerCase()};
                            </a>
                          </p>
                        </Col>
                      )
                    })}
                  </Row>
                  <div className="pt-3">
                    <hr />
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col lg={10} xl={8}>
                  <p
                    dangerouslySetInnerHTML={{ __html: formDescription }}
                    className={`${style.textColor} my-5 h5 text-left`}
                  />

                  <Form
                    id="contactForm"
                    action="#"
                    method="post"
                    onSubmit={handleSubmit}
                    onChange={validate}
                  >
                    <Card
                      className={view && view === "modal" ? "" : style.formBox}
                    >
                      <Card.Body className={`${style.textColor} py-4`}>
                        <Row className="d-flex justify-content-between pb-4">
                          <Col xs={12} sm={6} className="px-0 pr-sm-3">
                            <Form.Group controlId="fname">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="firstName"
                                placeholder="First name"
                                className="rounded-0 p-4"
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6} className="px-0 pl-sm-3">
                            <Form.Group controlId="lname">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="lastName"
                                placeholder="Last name"
                                className="rounded-0 p-4"
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="d-flex justify-content-between pb-4">
                          <Col xs={12} sm={6} className="px-0 pr-sm-3">
                            <Form.Group controlId="email">
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="e.g. jan@mailo.com"
                                className={`${style.formField} rounded-0 p-4`}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6} className="px-0 pl-sm-3">
                            <Form.Group controlId="phone">
                              <Form.Label>Phone Number</Form.Label>
                              <Form.Control
                                type="tel"
                                name="phone"
                                placeholder="Enter your mobile or landline"
                                className="rounded-0 p-4"
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* dropdown starts */}
                        <Row className={`pb-0 ${style.dropDownWrapper}`}>
                          <Col className="p-0">
                            <Form.Label>What is your enquiry about?</Form.Label>

                            {/* Here is certain! */}
                            <div className={`mb-5 w-100 position-relative`}>
                              <Form.Control
                                readOnly
                                inputMode="none"
                                className={` ${
                                  style.selectFormIconClass
                                } d-flex align-items-center position-absolute ${
                                  isDropdownListOpen ? style.listOpen : ""
                                }`}
                                onClick={() => {
                                  setIsDropdownListOpen(!isDropdownListOpen)
                                }}
                                placeholder="Please Select"
                                name="enquiry"
                                required
                                autoComplete="off"
                                style={{ zIndex: 1 }}
                                value={selectedEnquiry}
                              />
                              <div
                                type="button"
                                className={`${style.customDropdown} d-flex align-items-center position-relative`}
                                onClick={() => {
                                  setIsDropdownListOpen(!isDropdownListOpen)
                                }}
                              >
                                <ul
                                  className={`list-unstyled ${
                                    style.dropdownOverflowClass
                                  } ${
                                    isDropdownListOpen ? "d-block" : "d-none"
                                  }`}
                                >
                                  {enquiryOptions &&
                                    enquiryOptions.map((enquiryOptions, i) => {
                                      if (
                                        enquiryOptions.name === selectedEnquiry
                                      ) {
                                        return null
                                      }

                                      return (
                                        <li
                                          value={enquiryOptions.name}
                                          key={`reg-form-${i}}`}
                                          className={`
                                          ${
                                            isDropdownListOpen
                                              ? "d-block w-100 ps-6"
                                              : "d-none"
                                          }list-unstyled ps-0`}
                                          type="button"
                                          onClick={() =>
                                            setSelectedEnquiry(
                                              enquiryOptions.name
                                            )
                                          }
                                        >
                                          {" "}
                                          {enquiryOptions.name}
                                        </li>
                                      )
                                    })}
                                </ul>
                              </div>
                            </div>
                            {/* end of here is certain! */}
                          </Col>
                        </Row>

                        <Row className="pb-4">
                          <Col sm={12} className="px-0">
                            <Form.Group controlId="comment">
                              <Form.Label>Message</Form.Label>
                              <Form.Control
                                as="textarea"
                                name="comment"
                                rows="4"
                                className="rounded-0 p-4"
                                placeholder="How can we help you?"
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="d-flex justify-content-between align-items-center">
                          <Col xs={12} sm={7} className="px-0 pr-sm-3">
                            <div
                              className={`${style.checkboxGroup} form-group form-check`}
                            >
                              <label
                                className={`${style.checkboxContainer} form-check-label h6 text-left`}
                                htmlFor="terms"
                              >
                                <input
                                  type="checkbox"
                                  className={`${style.checkbox} form-check-input`}
                                  id="terms"
                                  required
                                />
                                <span className={style.checkmark}></span>I agree
                                to the{" "}
                                <a
                                  className="text-secondary"
                                  href={createLink(tcLink)}
                                >
                                  Little Village Terms & Conditions
                                </a>
                              </label>
                            </div>
                          </Col>
                          <Col xs={12} sm={5} className="px-0 pl-sm-3">
                            <Form.Group className="text-sm-right">
                              <Button
                                className={`${style.className} d-flex align-content-center align-items-center justify-content-center text-white w-100 py-2 py-sm-3`}
                                variant={
                                  btnDisabled === true
                                    ? "disabled"
                                    : "secondary"
                                }
                                size="lg"
                                type="submit"
                                disabled={btnDisabled}
                              >
                                Submit
                                <img
                                  className="pl-3"
                                  src={arrow}
                                  alt="right arrow"
                                />
                                {isSubmitting && <BtnLoader />}
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Fragment>
      )}

      {status && status.success && (
        <Container>
          <Row className={`${style.thankYouRow} d-flex justify-content-center`}>
            <Col
              lg={10}
              xl={8}
              className="d-flex flex-column align-items-center"
            >
              <h1>Thank You</h1>
              <p className="h5 py-3 py-md-4 py-lg-5">{status.success}</p>
              <p>
                <a className="h5 text-secondary" href="/">
                  Return to homepage
                </a>
              </p>
              <div className={`${style.image} py-3 py-md-4 py-lg-5`}>
                <GatsbySrcImage
                  img={data.heart}
                  className={`w-100 h-100`}
                  altText="Bib icon with a heart in the middle"
                />
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {status && status.exception && (
        <Alert variant="danger" className="my-5">
          <Alert.Heading>{status.exception}</Alert.Heading>
        </Alert>
      )}
    </section>
  )
}
