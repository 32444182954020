/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types"
import React from "react"

const Iframe = ({ title, src, className }) => (
  <iframe
    className={`${className}`}
    title={title}
    src={src} 
    frameBorder="none"
    scrolling="no"    
  />
)

export default Iframe

Iframe.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
}

Iframe.defaultProps = {
  title: null,
  className: null,  
}