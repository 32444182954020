import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from "./FourBoxes.module.scss"

export const fragment = graphql`
         fragment fourBoxesFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_FourBoxes {
           boxes {
             number
             paragraph
             source
           }
         }
       `

const boxStyles = {
  1: style.box1,
  2: style.box2,
  3: style.box3,
  4: style.box4,
}

export const FourBoxes = ({ boxes }) => {
  return (
    <section className="p-0 mb-2 mb-lg-5">
      <Container>
        <Row>
          {boxes.map((item, i) => {
            return (
              <Col
                xs={12}
                md={6}
                key={`FourBoxes-box-${i}`}
                className="mx-auto mb-4"
              >
                <div className={`card ${style.box} ${boxStyles[i + 1]}`}>
                  <div className={`position-relative h-100 bg-light`}>
                    <h1 className="text-center pt-4">{item.number}</h1>
                    <p className="pb-4 text-center font-weight-bold h5">
                      {item.paragraph}
                    </p>
                  </div>
                  <p className="card-body d-flex flex-column text-center pb-3 pb-lg-4">
                    {item.source}
                  </p>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}
