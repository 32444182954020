import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from "./ProductItems.module.scss"
import { Button } from "react-bootstrap"
import arrow from "../images/chevron-right.png"
import React, { Fragment, useState } from "react"
import { basketActions, basketSelectors } from "../state/basket"
import { connect } from "react-redux"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useScrollPosition } from "../utils/useScrollPosition"

const ProductItems = ({
  productItems,
  items,
  addItemToBasket,
  plusItemCount,
  minusItemCount,
  basket,
  total,
  totalPrice,
}) => {
  const [hideOnScroll, setHideOnScroll] = useState(false)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y && currPos.y < -80
      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow)
      }
    },
    [hideOnScroll],
    false,
    false,
    200
  )

  const getValue = ({ price }) => +price.slice(1) || 0
  productItems.sort((a, b) => getValue(a) - getValue(b))

  const renderStandardProductItems = (fixed = true) => {
    return (
      <Fragment>
        <section className="py-4  mb-lg-0">
          <div>
            <Container fluid={true}>
              <Row className="rowContainer">
                {productItems.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      className="mb-4 d-flex mx-auto"
                      sm={12}
                      md={6}
                      xl={4}
                    >
                      <div className="mx-auto">
                        <div className={`mt-4 mb-2 card ${style.box}`}>
                          <img src={item.image.sourceUrl} alt="product-image" />
                        </div>

                        <div className={`card ${style.boxText}`}>
                          <div className="card-body mt-3 mb-2">
                            <p
                              className={`font-weight-bold  ${style.nameText}`}
                              dangerouslySetInnerHTML={{ __html: item.name }}
                            />
                            <p
                              className={`card-text mb-4 ${style.text}`}
                              dangerouslySetInnerHTML={{
                                __html: item.description.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                ),
                              }}
                            />
                            <h5
                              className="card-text font-weight-bold text-left"
                              dangerouslySetInnerHTML={{ __html: item.price }}
                            />

                            {items.hasOwnProperty(item.id) ? (
                              <div className="d-flex align-items-end">
                                <div
                                  className={`buttonLink mt-3 font-weight-normal p-0 pl-3 justify-content-between  ${style.whiteButton}`}
                                  onClick={() => addItemToBasket(item)}
                                >
                                  Added to basket:
                                  <p
                                    className={`text-dark font-weight-bold  ${style.number}`}
                                  >
                                    {" "}
                                    {items[item.id]}
                                  </p>
                                </div>

                                <div className="d-flex flex-column">
                                  <button
                                    className={`${style.btn} ${style.upIcon}`}
                                    onClick={() => plusItemCount(item.id)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faChevronUp}
                                      className={`${style.icon} `}
                                    />
                                  </button>
                                  <button
                                    className={style.btn}
                                    onClick={() => minusItemCount(item.id)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faChevronDown}
                                      className={`${style.icon} `}
                                    />
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <Button
                                className="w-100 mt-3"
                                variant="secondary"
                                size="lg"
                                onClick={() => addItemToBasket(item)}
                              >
                                Add to basket
                                <img
                                  src={arrow}
                                  alt="icon"
                                  className="ml-3"
                                  width={10}
                                  height={16}
                                />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </div>
        </section>
      </Fragment>
    )
  }

  return <Fragment>{renderStandardProductItems(hideOnScroll)}</Fragment>
}

const mapStateToProps = state => ({
  items: basketSelectors.getItemsIdAndCount(state),
  basket: basketSelectors.getItems(state),
  total: basketSelectors.getTotalItems(state),
  totalPrice: basketSelectors.getTotalItemsValue(state),
})

const mapDispatchToProps = {
  addItemToBasket: basketActions.addItemToBasket,
  plusItemCount: basketActions.plusItemCount,
  minusItemCount: basketActions.minusItemCount,
  setItemCount: basketActions.setItemCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductItems)
