import React, {Component} from 'react'
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import * as PropTypes from 'prop-types'

//https://www.npmjs.com/package/google-maps-react

export class MapContainer extends Component {

  render() {

    const style = {
      width: '100%',
      height: '100%'
    }

    const containerStyle = {
      position: 'relative',
      width: '100%',
      height: '392px',
      top:'-54px'
    }

    return (
      <Map google={this.props.google}
           initialCenter={{
             lat: this.props.locationLatitude,
             lng: this.props.locationLongitude
           }}
           zoom={15}
           style={style}
           containerStyle={containerStyle}
      >
        <Marker
          title={this.props.name}
          name={this.props.name}
          position={{lat: this.props.locationLatitude,
            lng: this.props.locationLongitude}} />
      </Map>
    );
  }
}

MapContainer.propTypes = {
  locationLabel: PropTypes.string.isRequired,
  locationLatitude: PropTypes.string.isRequired,
  locationLongitude: PropTypes.string.isRequired,
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBDKbd71VbPoHnRIa0OO0r_8Ctgje8BQKo'
})(MapContainer)