
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from './HeaderParagraph.module.scss';
import { StickyHelp } from "../UI/StickyHelp"


export const fragment = graphql`
  fragment headerParagraphFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_HeaderParagraph {  
     sectionHeader
     paragraph
     
  }
`

export const HeaderParagraph = ({sectionHeader,paragraph}) => {

  return (
        <>
          <Container fluid={true}  >
            <Row >
              <Col sm={12}>
                <StickyHelp />
                <h1 className={`mb-4 mt-5 ${style.title}`} dangerouslySetInnerHTML={{ __html: sectionHeader }} />
                <h4 className={` mb-7 ${style.text}`} dangerouslySetInnerHTML={{ __html: paragraph }} />
              </Col>
            </Row>
          </Container>
        </>
  )
}

