
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import style from "./BorderBox.module.scss"
import { LinkButton } from "../LinkButton"

export const fragment = graphql`
         fragment borderBoxFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_BorderBox {
           sectionHeader
           paragraph
           button {
             title
             url
           }
         }
       `

export const BorderBox = ({ sectionHeader, paragraph, button }) => {
  return (
    <section className="py-5 my-lg-4">
      <Container>
        <Row className={style.row}>
          <Col xs={12} className="mx-auto ">
            <div className={` py-3 px-3  pb-5 ${style.box}`}>
              <h2
                className={` mt-4 mb-4`}
                dangerouslySetInnerHTML={{ __html: sectionHeader }}
              />

              <div
                className={` font-weight-normal ${style.text}`}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />

              {button ? (
                <div className="d-flex justify-content-center">
                  <LinkButton
                    href={button.url}
                    title={button.title}
                    arrowIcon
                  />
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
