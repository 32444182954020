
import { graphql } from "gatsby"
import React from "react"
import {GatsbyImage} from "../GatsbyImage"


export const fragment = graphql`
  fragment bannerImageFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_BannerImage {  
     bannerImage {
          ...GatsbyImageQuery
        }
  }
`

export const BannerImage = ({bannerImage}) => {

  return (
    <div >
      <GatsbyImage img={bannerImage}  />
    </div>
  )
}

