import { graphql } from "gatsby"
import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from "./Policies.module.scss"
import { Link } from "../Link"
import "font-awesome/css/font-awesome.min.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

export const fragment = graphql`
  fragment policiesFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_Policies {
    sectionHeader
    description
    policies {
      policyName {
        title
        url
      }
    }
  }
`

export const Policies = ({ sectionHeader, description, policies }) => {
  return (
    <section>
      <Row>
        <Col sm={12}>
          <h1>{sectionHeader}</h1>
          <p
            className="h5 text-center font-weight-normal my-4 pb-4"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Col>
      </Row>

      <div className={style.policiesContainer}>
        <Row>
          {policies.map((item, i) => {
            return (
              <Col key={`col-1-policy-item-${i}`} sm={12} md={6}>
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className={style.chevronIcon}
                  />
                  <Link
                    href={item.policyName.url}
                    className={`h5 ${style.text}`}
                  >
                    {item.policyName.title}
                  </Link>
                </div>
              </Col>
            )
          })}
        </Row>
      </div>
    </section>
  )
}
