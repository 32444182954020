
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import BackgroundImage from "gatsby-background-image"
import style from './Team.module.scss';

export const fragment = graphql`
  fragment teamFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_Team {  
        sectionHeader
        paragraph
        backgroundImage {
          ...GatsbyImageQuery
        }
        team {
          name
          jobTitle
          description
          image {
          ...GatsbyImageQuery
          }
        }
     
  }
`

export const Team = ({sectionHeader,paragraph,backgroundImage,team}) => {

  return (
    <BackgroundImage
      fluid={backgroundImage.imageFile.childImageSharp.fluid} className="bg"
    >
        <section >
          <Container className={`${style.textColor}`}>
            <h2 className={`text-center pb-4 ${style.textColor}`} dangerouslySetInnerHTML={{ __html: sectionHeader }} />
            <p className={`text-center font-weight-normal mb-5 ${style.paragraphText}`} dangerouslySetInnerHTML={{ __html: paragraph }} />
            <Row className={style.row}>
              {
                team.map((item,i) => {
                  return(
                    <Col xs={10} sm={8} md={6} xl={4} key={i} className="mx-auto mb-4">
                      <div className={`card  mb-5 ${style.box}`} >
                        <img className="card-img-top" src={item.image.sourceUrl} alt="Card" />

                        <div className="card-body d-flex flex-column">
                          <h5 className={`${style.nameText} text-left`} dangerouslySetInnerHTML={{ __html: item.name }} />
                          <p className={style.job} dangerouslySetInnerHTML={{ __html: item.jobTitle }} />
                          <div dangerouslySetInnerHTML={{ __html: item.description }} />
                        </div>
                      </div>
                    </Col>
                  )
                })}
            </Row>
          </Container>
        </section>
    </BackgroundImage>
  )
}
