import { graphql } from "gatsby"
import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { GatsbyImage } from "../GatsbyImage"
import { LinkButton } from "../LinkButton"
import style from "./MainBanner.module.scss"
import { StickyHelp } from "../UI/StickyHelp"

export const fragment = graphql`
  fragment mainBannerFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_ImageAndTextSlider {
    sliderContent {
      backgroundColor
      button {
        url
        title
      }
      image {
        ...GatsbyImageQuery
        id
      }
      text
    }
  }
`

export const MainBanner = ({ sliderContent }) => {
  const { backgroundColor, text, button, image } = sliderContent[0] || {}

  return (
    <div className="position-relative">
      <StickyHelp />
      <div style={{ backgroundColor }}>
        <Container fluid={"xxl"} className={`${style.container} pb-9`}>
          <Row noGutters={true}>
            <Col
              xs={{ span: 12, order: "last" }}
              md={{ span: 12 }}
              lg={{ span: 6, order: "first" }}
              xl={{ order: "first" }}
            >
              <div
                className={`${style.bannerContent} d-flex flex-column justify-content`}
              >
                <div>
                  <div>
                    <h2 className={`${style.slideTitle} super-curious`}>
                      {text}
                    </h2>
                  </div>

                  {button ? (
                    <div className={`${style.button} mt-3 mt-lg-5`}>
                      <LinkButton href={button?.url} title={button?.title} />
                    </div>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 12, order: "first" }}
              md={{ span: 12 }}
              lg={{ span: 6, order: "last" }}
              xl={{ order: "last" }}
            >
              <div
                className={`${style.slideContainer} d-flex flex-column justify-content-center align-items-end`}
              >
                <GatsbyImage
                  id="heart-path"
                  img={image}
                  className={`${style.slideImage} w-100`}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
