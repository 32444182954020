import React, { useState, Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import style from "./ExternalContactForm.module.scss"
import Iframe from "../UI/Iframe"

export const fragment = graphql`
  fragment ExternalContactFormFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_ExternalContactForm {
    sectionHeading
    formUrl
  }
`
export const ExternalContactForm = ({ sectionHeading, text, formUrl }) => (
  <div className={style.iframeContainer}>
    <Iframe src={formUrl} className={style.iframe} />
  </div>
)
