import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import BackgroundImage from "gatsby-background-image"
import style from "./TwoBoxesWithBgimg.module.scss"
import { createLink } from "../../utils"
import arrow from "../../images/chevron.svg"

export const fragment = graphql`
         fragment twoBoxesWithBgimgFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_TwoBoxesWithBgimg {
           backgroundImage {
             ...GatsbyImageQuery
           }
           buttonLink {
             url
             title
           }
           boxes {
             title
             paragraph
             linkTo {
               url
               title
             }
           }
         }
       `

export const TwoBoxesWithBgimg = ({ backgroundImage, buttonLink, boxes }) => {
  return (
    <BackgroundImage fluid={backgroundImage.imageFile.childImageSharp.fluid}>
      <div className="py-5  mb-lg-0">
        <Container>
          <Row className={style.row}>
            {boxes.map((item, i) => {
              return (
                <Col xs={12} md={6} key={i} className="mx-auto ">
                  <div className={`mb-4 mt-3 py-3 px-3 ${style.box}`}>
                    <h4
                      className={`${style.nameText} text-white mt-3`}
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <p
                      className={`h5 text-white  mt-3 ${style.text}`}
                      dangerouslySetInnerHTML={{ __html: item.paragraph }}
                    />

                    <a href={createLink(item.linkTo.url)}>
                      <div className={style.circle}>
                        <img className={style.icon} src={arrow} alt="" />
                      </div>
                    </a>
                  </div>
                </Col>
              )
            })}
          </Row>

          {buttonLink && (
            <a className={style.link} href={createLink(buttonLink.url)}>
              {buttonLink.title}
            </a>
          )}
        </Container>
      </div>
    </BackgroundImage>
  )
}
