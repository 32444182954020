import axios from 'axios';

const API_KEY = 'AIzaSyBDKbd71VbPoHnRIa0OO0r_8Ctgje8BQKo';
const BASE_URL = 'https://maps.googleapis.com';

export const fetchGeoLocation = (postcode) => {
  const params = {
    key: API_KEY,
    components: 'GB',
    address: postcode,
  };

  const config = {
    baseURL: BASE_URL,
    params,
  };

  return axios.get(`/maps/api/geocode/json`, config);
};