import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import { GatsbyImage } from "../GatsbyImage"
import { LinkButton } from "../LinkButton"
import BackgroundImage from "gatsby-background-image"
import style from "./TwoInfoBoxesWithIconsLightBkgd.module.scss"
import rightArrow from "../../images/chevron-right.png"
import { createLink } from "../../utils"

export const fragment = graphql`
  fragment twoInfoBoxesWithIconsLightBkgdFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_TwoInfoBoxesWithIconsLightBackground {
    sectionHeading
    infoBoxes {
      description
      icon {
        altText
        sourceUrl
        imageFile {
          childImageSharp {
            fluid(maxWidth: 58, pngQuality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      infoBoxHeading
      linkButton {
        url
      }
    }
    linkButton {
      url
      title
    }
  }
`

const getData = graphql`
  {
    bkgdDots: file(relativePath: { eq: "bkgd-dots.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const TwoInfoBoxesWithIconsLightBkgd = ({
  sectionHeading,
  infoBoxes,
  linkButton,
}) => {
  const data = useStaticQuery(getData)
  return (
    <>
      <BackgroundImage
        fluid={data.bkgdDots.childImageSharp.fluid}
        id={style.backgroundImage}
      >
        <Container>
          <Row className="d-flex justify-content-center">
            <h2>{sectionHeading}</h2>
            <Row
              noGutters={true}
              className="d-flex justify-content-center pt-4 pb-5 pt-lg-6 pb-lg-7 mx-2"
            >
              {infoBoxes.map((item, index) => (
                <Col
                  xs={12}
                  md={8}
                  lg={5}
                  className={`${style.infoBox} col-xxl-4 d-flex flex-column align-items-center m-3`}
                  key={index}
                >
                  <div
                    className="pt-5 pb-4"
                    style={{ maxWidth: "58px", width: "100%" }}
                  >
                    <GatsbyImage img={item.icon} />
                  </div>
                  <h3 className="text-secondary pb-4">{item.infoBoxHeading}</h3>
                  <p className="lead text-secondary text-center px-2 px-xl-3 pb-4">
                    {item.description}
                  </p>

                  <div className="mb-5">
                    <Button
                      variant="secondary"
                      href={createLink(item.linkButton.url)}
                      className="rounded-circle"
                    >
                      <img
                        src={rightArrow}
                        alt={"next"}
                        className="py-2 pl-2 pr-1"
                      />
                    </Button>
                  </div>
                </Col>
              ))}
            </Row>
          </Row>
        </Container>
      </BackgroundImage>
    </>
  )
}
