import React from "react"
import { graphql} from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import BackgroundImage from "gatsby-background-image"

export const fragment = graphql`
  fragment textBlockFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_TextBlock {
    sectionHeading
    sectionHeadingType
    text
    backgroundImage {
       ...GatsbyImageQuery
    }
  }
`

export const TextBlock = ({ sectionHeading,sectionHeadingType, text,backgroundImage }) => {

  return (
    <>

      <BackgroundImage
        fluid={backgroundImage.imageFile.childImageSharp.fluid} className="bg"
      >
        <Container className="mb-8 mb-lg-0">
          <Row className="d-flex justify-content-center">
            <Col
              xs={11}
              lg={10}
              xl={9}
              className={`bg-white d-flex flex-column align-items-center my-6 px-md-9`}
            >
              <div className="pt-4hs pb-4">
                {sectionHeading && (sectionHeadingType ==='H1' && <h1 dangerouslySetInnerHTML={{ __html:sectionHeading }} />)}
                {sectionHeading && (sectionHeadingType ==='H2' &&  <h2 dangerouslySetInnerHTML={{ __html:sectionHeading }} />)}
                <div
                  dangerouslySetInnerHTML={{ __html: text }}
                  className="h6 text-left pt-3 pb-sm-1 pb-lg-3 px-2 px-xl-3 font-weight-normal"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </BackgroundImage>
    </>
  )
}
