import React from "react"
import { graphql } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { GatsbyImage } from "../GatsbyImage"
import style from "./TwoImgsWithBtns.module.scss"
import { LinkButton } from "../LinkButton"

export const fragment = graphql`
  fragment twoImgsWithBtnsFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_TwoImgsWithBtns {
    sectionHeading
    images {
      button {
        title
        url
      }
      image {
        altText
        ...GatsbyImageQuery
      }
    }
  }
`

export const TwoImgsWithBtns = ({ sectionHeading, images }) => {
  return (
    <>
      <Container className="py-4 py-lg-5">
        <Row className="d-flex justify-content-center">
          {sectionHeading && <h2>{sectionHeading}</h2>}
        </Row>
        <Row noGutters={true} className="d-flex justify-content-center">
          {images.map((item, index) => {
            const { button, image } = item
            return (
              <Col
                xs={12}
                md={8}
                lg={5}
                className={`style.infoBox col-xxl-4 d-flex flex-column align-items-between m-3`}
                key={index}
              >
                <GatsbyImage
                  img={image}
                  className={`${style.image} pt-sm-3 pt-sm-5 `}
                />

                {button ? (
                  <div className="d-flex justify-content-center">
                    <LinkButton
                      href={button.url}
                      title={button.title}
                      arrowIcon
                    />
                  </div>
                ) : null}
              </Col>
            )
          })}
        </Row>
      </Container>
    </>
  )
}
