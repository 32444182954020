
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from './HeaderAndList.module.scss';
import BackgroundImage from "gatsby-background-image"



export const fragment = graphql`
  fragment headerAndListFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_HeaderAndList {  
      sectionHeader
      date
      bottomParagraph
      paragraph
      list {
        listText
        enabled
        circleColor
      }
      backgroundImg {
         ...GatsbyImageQuery
    }
     
  }
`


export const HeaderAndList = ({sectionHeader,date,list,backgroundImg,bottomParagraph,paragraph}) => {

  return (
    <div className="section">
      <BackgroundImage
        fluid={backgroundImg.imageFile.childImageSharp.fluid} className={style.bg}
      >
        <section >
          <Row >
            <Col sm={12}>
              <h1 className={`${style.title}`} dangerouslySetInnerHTML={{ __html: sectionHeader }} />
              {date && <h5 className={style.text} dangerouslySetInnerHTML={{ __html: date }} />}
            </Col>
          </Row>

          <Container fluid={true}  className={style.mainContainer}>
            <Row>
              {paragraph && <div className={style.paragraphText} dangerouslySetInnerHTML={{ __html: paragraph }} />}
                { list && list.map((item, i) => {
                    if (item.enabled){
                      return (
                        <Col sm={12} md={6}  className="d-flex align-items-baseline " key={i}>
                          <div  className={style.dot} style={{backgroundColor: `${item.circleColor}`,}}></div>
                          {item.listText && <div className={style.listName} dangerouslySetInnerHTML={{ __html: item.listText }} />}
                        </Col>
                      )
                    }
                  })
                }
              {bottomParagraph && <div className={`pt-4 ${style.paragraphText}`} dangerouslySetInnerHTML={{ __html: bottomParagraph }} />}
            </Row>
          </Container>
        </section>
      </BackgroundImage>
    </div>
  )
}

