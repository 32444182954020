
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import style from './TwoBoxesWithoutBgimg.module.scss';
import { createLink } from "../../utils"
import arrow from "../../images/arrow-right.png"

export const fragment = graphql`
  fragment twoBoxesWithoutBgimgFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_TwoBoxesWithoutBgimg {  
      twoBoxes {
        title
        paragraph
        button {
          title
          url
        }
      }
}
`

export const TwoBoxesWithoutBgimg = ({twoBoxes}) => {
  return (
      <section className="py-5 my-lg-5" >
        <Container>
          <Row className="rowContainer">
            {
              twoBoxes.map((item,i) => {
                return (
                  <Col xs={12} md={6} key={i} className="mx-auto ">
                    <div className={` py-3 px-3  pb-5 ${style.box}`}>
                      <h3
                        className={`${style.nameText}  mt-4 mb-4`}
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      <div className="mt-auto">
                        <h5
                          className={` font-weight-normal ${style.text}`}
                          dangerouslySetInnerHTML={{ __html: item.paragraph }}
                        />
                        <a
                          className={style.link}
                          href={createLink(item.button.url)}
                        >
                          <h5 className={`font-weight-bold ${style.linkText}`}>
                            {item.button.title}{" "}
                          </h5>
                        </a>
                      </div>
                    </div>
                  </Col>
                )
              })}
          </Row>
        </Container>
      </section>
  )
}
