import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

import { ACFPageComponents } from "../components/AcfPageBlocks"

const Page = ({ location, data }) => {
  const {
    title,
    content,
    flexLayouts: { flexibleLayouts },
    seo: { seoComponents },
  } = data.wpgraphql.page

  let seoComponent

  if (seoComponents.length > 0) {
    seoComponents.map(component => {
      switch (component.__typename) {
        case "WPGraphQL_Page_Seo_SeoComponents_SeoComponents": {
          seoComponent = component
          console.log("pages build SEO", seoComponent)
          break
        }
        default:
          console.log(component.__typename + " for SEO?")
      }
    })
  }

  if (
    !seoComponent ||
    seoComponent.seoTitle === null ||
    seoComponent.seoTitle === ""
  ) {
    seoComponent = { seoTitle: title }
  }

  return (
    <Layout seoComponent={seoComponent} location={location}>
      <main>
        {content && (
          <div
            className={title}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {flexibleLayouts.length > 0
          ? flexibleLayouts.map((block, i) => {
              const componentName = block.__typename.replace(
                /WPGraphQL_Page_Flexlayouts_FlexibleLayouts_/,
                ""
              )

              const Component = ACFPageComponents[componentName]

              if (!Component) {
                console.warn(
                  `Oh my! There was a problem rendering ${componentName}.`
                )
                return null
              }

              return (
                <Component
                  key={`FlexibleLayouts_${i}_${componentName}`}
                  {...block}
                />
              )
            })
          : null}
      </main>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query GET_PAGE($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        content
        uri
        date
        flexLayouts {
          flexibleLayouts {
            __typename
            ...bannerImageFragment
            ...headerParagraphFragment
            ...mainBannerFragment
            ...policiesFragment
            ...policyDetailFragment
            ...textBoxOnFullWidthImgFragment
            ...statsSliderFragment
            ...headerInWhiteBoxFragment
            ...headerAndListFragment
            ...twoInfoBoxesWithIconsLightBkgdFragment
            ...textBlockFragment
            ...twoImgsWithBtnsFragment
            ...quoteSliderFragment
            ...latestNewsFragment
            ...tipsForDonatingFragment
            ...whenWhereToDonateFragment
            ...headerImgagesAndBtnFragment
            ...headerOneImgAndBtnFragment
            ...supportersFragment
            ...teamFragment
            ...fourBoxesFragment
            ...videoComponentFragment
            ...twoBoxesWithBgimgFragment
            ...contactPageFragment
            ...threeBoxesFragment
            ...leftImageFragment
            ...twoBoxesWithoutBgimgFragment
            ...borderBoxFragment
            ...whiteBoxWithBgimgFragment
            ...wishlistFragment
            ...shopBannerFragment
            ...shopProductsFragment
            ...instagramFeedFragment
            ...ExternalContactFormFragment
          }
        }
        seo {
          seoComponents {
            __typename
            ...metadataSectionFragment
          }
        }
      }
    }
  }
`
