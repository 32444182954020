import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import BackgroundImage from "gatsby-background-image"
import style from "./Supporters.module.scss"

import { LinkButton } from "../LinkButton"

export const fragment = graphql`
         fragment supportersFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_Supporters {
           sectionHeader
           backgroundImage {
             ...GatsbyImageQuery
           }
           buttonLink {
             title
             url
           }
           companies {
             image {
               ...GatsbyImageQuery
             }
             imageLink {
               title
               url
             }
           }
         }
       `

export const Supporters = ({
  sectionHeader,
  backgroundImage,
  buttonLink,
  companies,
}) => {
  return (
    <BackgroundImage
      fluid={backgroundImage.imageFile.childImageSharp.fluid}
      className="bg"
    >
      <section className="section  mb-lg-0">
        <div>
          <Container fluid={true} className={style.container}>
            <h2
              dangerouslySetInnerHTML={{ __html: sectionHeader }}
              className="mb-5 pb-3"
            />
            <Row className="mx-auto">
              {companies.map(member => {
                return (
                  <Col
                    sm={12}
                    md={6}
                    lg={4}
                    xl={3}
                    className="d-flex flex-column p-0 mb-4"
                  >
                    <div className={style.box}>
                      <a href={member.imageLink.url} target="_blank">
                        <img
                          src={member.image.sourceUrl}
                          alt={member.image.title}
                          className="mx-auto img-fluid"
                        />
                      </a>
                    </div>
                  </Col>
                )
              })}
            </Row>

            {buttonLink ? (
              <div className="d-flex justify-content-center my-5">
                <LinkButton
                  href={buttonLink.url}
                  title={buttonLink.title}
                  arrowIcon
                />
              </div>
            ) : null}
          </Container>
        </div>
      </section>
    </BackgroundImage>
  )
}
