
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import BackgroundImage from "gatsby-background-image"
import {Video} from "./Video";

export const fragment = graphql`
  fragment videoComponentFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_VideoComponent {
      videoSection
      sectionHeader
      backgroundImage {
       ...GatsbyImageQuery
      }    
  }
`

export const VideoComponent = ({videoSection,backgroundImage, sectionHeader}) => {

  return (
      <BackgroundImage
        fluid={backgroundImage.imageFile.childImageSharp.fluid}
      >
        <section className="section">
          <Container fluid={true} >
            <h3 dangerouslySetInnerHTML={{ __html: sectionHeader }} className="mb-4 text-white" />
            <Video video={videoSection} />
          </Container>
        </section>
      </BackgroundImage>
  )
}


