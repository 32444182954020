import ProductItems from "../ProductItems"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import BackgroundImage from "gatsby-background-image"


export const fragment = graphql`
  fragment shopProductsFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_ShopProducts {
      backgroundImage {
          ...GatsbyImageQuery
      }
  }
`

export const ShopProducts = ({backgroundImage}) => {
  const Shop_QUERY = graphql`
  query GET_SHOP_ITEMS {
    wpgraphql {
     products {
        nodes {
          name
           ... on WPGraphQL_SimpleProduct {
            id
            name
            rawPrice:price(format: RAW)
            price
            description
            image {
               ...GatsbyImageQuery
            }
          }
        }
      }
    }
  }
`;


  const data = useStaticQuery(Shop_QUERY);
  const dataProducts = data.wpgraphql.products.nodes

  return (
    <BackgroundImage
      fluid={backgroundImage.imageFile.childImageSharp.fluid} className="bg"
    >
      <ProductItems productItems={dataProducts} />
    </BackgroundImage>
  )
}

