

import { graphql } from "gatsby"
import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from './WhenWhereToDonate.module.scss';
import BackgroundImage from "gatsby-background-image"
import WhereAreYourBased from "./WhereAreYourBased"
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import searchIcon from '../../images/search.png'
import Button from "react-bootstrap/Button"
import chevronRight from '../../images/chevron-right.png'
import refreshIcon from '../../images/refresh.png'
import Form from 'react-bootstrap/Form'
import {fetchGeoLocation} from "../../api/google"
import {compareDistance} from "../../utils/locations"

import envelope from "../../images/envelope-small.png"
import clock from "../../images/clock.png"
import pin from "../../images/map-pin.png"


export const fragment = graphql`
  fragment whenWhereToDonateFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_WhenWhereToDonate {  
    sectionHeader
    paragraph
    yesAnswer
    noAnswer
    donationTitle
    basedQuestion
    shopInfo {
        name
        email
        addressLine1
        addressLine2
        postCode
        openingHours
        latitude
        longitude
        noteTitle
        noteParagraph
        noProducts {
          productsTitle
        }
    }
    backgroundImage {
      ...GatsbyImageQuery
    }
    buttonLink {
      title
      url
    }
  }
`

export const WhenWhereToDonate = ({sectionHeader,paragraph,donationTitle,basedQuestion,backgroundImage,buttonLink,yesAnswer,noAnswer,shopInfo,notes}) => {


  const [showLocation, setShowLocation] = useState(false);
  const [searchingLocation, setSearchingLocation] = useState(null);
  const [validated, setValidated] = useState(false);
  const [showResults, setShowResults] = useState(true)
  const [showResults2, setShowResults2] = useState(false)


  // const regex = "^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$";

  const handleButtonClick=() =>{
    setShowResults(false);
    setShowResults2(false);
  }

  const onClick = () => {
    setShowResults2(true);
    setShowResults(true);

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      setShowLocation(true);
      setValidated(false);

      let postcode = form.elements['postcode'].value;

      fetchGeoLocation(postcode) .then(response => {
        let latitude;
        let longitude;
        if(response.data.results && response.data.results.length && response.data.results.length>0){
          const location = response.data.results[0].geometry.location;

          latitude = location.lat;
          longitude = location.lng;

          let minDistance = 0;
          let result = null;

          shopInfo.forEach((item)=> {
            let distance = Math.abs(compareDistance(latitude,longitude,item.latitude, item.longitude));

            if(result === null){
              result = item;
              minDistance = distance;
            } else if(minDistance > distance) {
              result = item;
              minDistance = distance;
            }
          });


          setSearchingLocation(result);

        } else {

          //TODO: no results from google map
          //latitude = 51.5075467;
          //longitude = -0.1278833;

          setSearchingLocation(null);
          //setSearchingLocation(places[0]);
        }
      }).catch(error => {
          console.error('Error', error);
        })

    } else {
      setValidated(true);
    }

  }

  const handleReset = (event) => {
    event.stopPropagation();

    setValidated(false);
    setShowLocation(false);
    setSearchingLocation(null);
  }


  return (
    <>

    <div className="section mb-4 mb-lg-0">
      <BackgroundImage
        fluid={backgroundImage.imageFile.childImageSharp.fluid} className="bg"
      >
        <section  >
          <Container fluid={true} >
              <h1 className={`mb-4 h2`} dangerouslySetInnerHTML={{ __html: sectionHeader }} />
              <h5 className={` mb-5 ${style.text}`} dangerouslySetInnerHTML={{ __html: paragraph }} />

              <div className={style.mainContainer}>
                <h3 className={`mb-5 text-center`} dangerouslySetInnerHTML={{ __html: donationTitle }} />
                <Row  className={`mb-4 ${style.row}`}>

                    <Col sm={12} md={6} >
                      <div className={style.answerBox}>
                        <div className={`form-check  d-flex justify-content-between w-100 ${style.checkDiv}`}>
                          <label className={`form-check-label position-relative d-block `} htmlFor="exampleRadios1">
                            <p className={style.answerText} dangerouslySetInnerHTML={{ __html:noAnswer }} />
                            <input className={`form-check-input position-relative ${style.circleInput}`} type="radio" name="exampleRadios"
                                   id="exampleRadios1" value="option1" onClick={onClick}/>
                          </label>
                        </div>

                      </div>
                    </Col>

                  <Col sm={12} md={6}  >
                    <div className={style.answerBox}>
                      <div className={`form-check  d-flex justify-content-between w-100 ${style.checkDiv}`}>
                        <label className={`form-check-label position-relative d-block `} htmlFor="exampleRadios1">
                          <p className={style.answerText} dangerouslySetInnerHTML={{ __html:yesAnswer }} />
                          <input className={`form-check-input position-relative ${style.circleInput}`} type="radio" name="exampleRadios"
                                 id="exampleRadios1" value="option1"  onClick={handleButtonClick}/>
                        </label>
                      </div>
                    </div>
                  </Col>

                </Row>

                { showResults2  ?
                  (
                    <Row>
                      <Col>
                        <h5 className={`font-weight-normal mb-lg-4 ${style.noText}`}>Thanks for thinking of us. If your item is on our avoid list, then please feel free to donate to a local charity shop. If however it is something that we typically receive but not right now, hold on to it, and keep checking back on here and on our social channels for when we may need these things.</h5>
                      </Col>
                    </Row>

                  )
                  : null
                }
              </div>


            { showResults ?
              (
                null
              ):
              (
                <Form noValidate validated={validated} onSubmit={handleSubmit} onReset={handleReset}>
                  <div className={style.mainContainer}>
                    <Row>
                      <Col>

                        <h3 className={`mb-5 text-center`} dangerouslySetInnerHTML={{ __html: basedQuestion }} />
                        <div className={style.whereAreYouBasedContainer}>
                          <p className="mb-0">
                            <label for={'postcode'}>Postcode</label>
                          </p>
                          <InputGroup className="mb-6">
                            <FormControl
                              id="postcode"
                              aria-label="Postcode"
                              aria-describedby="search-input"
                              className={'border-0 rounded-0 bg-light text-uppercase'}
                              maxlength={8}
                              required={true}
                              // pattern={regex}
                            />
                            <InputGroup.Prepend>
                              <InputGroup.Text id="search-input" className={'border-0 rounded-0 bg-light'}>
                                <img src={searchIcon} alt="icon" width={24} height={24}/>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control.Feedback type="invalid">
                              Please enter valid code.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </div>

                        <div className={style.whiteDiv}>
                          {
                            shopInfo.map((item, i) => {
                              if (searchingLocation && item.name === searchingLocation.name) {
                                return (
                                  <>
                                  <h5  key={i} className="font-weight-normal pt-2"> Your nearest location is: <span className="font-weight-bold">{searchingLocation.name}</span></h5>
                                  </>
                                )}
                            })
                          }
                        </div>

                        { searchingLocation &&<WhereAreYourBased locationLabel={searchingLocation.name} locationLatitude={searchingLocation.latitude} locationLongitude={searchingLocation.longitude} />}

                        <div>
                          <Row className={style.companyDetails}>
                            <Col lg={6} >
                              {
                                shopInfo.map((item, i) => {
                                  if (searchingLocation && item.name === searchingLocation.name) {
                                  return (
                                    <div className="clearfix">
                                      <h5  className="text-left my-4" key={i} dangerouslySetInnerHTML={{ __html: item.name }} />
                                      <div className={`d-flex justify-content-center float-left ${style.iconDiv}`}>
                                        <img src={envelope} alt="icon" className={`mr-3 ${style.icon}`}/>
                                        <h5 className={style.address} dangerouslySetInnerHTML={{ __html: item.email }} />
                                      </div>
                                      <div className={`d-flex justify-content-center float-left ${style.iconDiv} ${style.postCodeNumber}`}>
                                        <img src={pin} alt="icon" className={`mr-3 ${style.icon}`}/>
                                        <h5 className={style.address} dangerouslySetInnerHTML={{ __html: item.addressLine1 }} />
                                      </div>
                                      <h5 className={`float-left ${style.location} ${style.address} ${style.postCodeNumber}`} dangerouslySetInnerHTML={{ __html: item.addressLine2 }} />
                                      <h5 className={`float-left ${style.address} ${style.location}  ${style.postCodeNumber}`} dangerouslySetInnerHTML={{ __html: item.postCode }} />
                                      <div className={`d-flex justify-content-center float-left ${style.iconDiv} ${style.postCodeNumber}`}>
                                        <img src={clock} alt="icon" className={`mr-3 ${style.icon}`}/>
                                        <h5 className={style.address} dangerouslySetInnerHTML={{ __html: item.openingHours }} />
                                      </div>
                                    </div>
                                  )}
                                })
                              }
                            </Col>

                            <Col lg={6} className={style.noteDiv}>
                              {
                                shopInfo.map((item, i) => {
                                  if (searchingLocation && item.name === searchingLocation.name) {
                                    return (
                                      <>
                                      <h5 key={i} className={style.note} dangerouslySetInnerHTML={{ __html: item.noteTitle }} />
                                      <h5 className={`${style.address}`} dangerouslySetInnerHTML={{ __html: item.noteParagraph }} />
                                        <ul className={style.list}>
                                          {item.noProducts && item.noProducts.map((product, i) => {
                                            return (
                                              <>
                                                <li key={i} className={style.address}>
                                                    {product.productsTitle && <div dangerouslySetInnerHTML={{ __html: product.productsTitle }} />}
                                                </li>
                                              </>
                                            )
                                          })}
                                        </ul>
                                      </>
                                    )}
                                })
                              }

                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className={'text-center pt-4'}>
                    {!showLocation ?
                      <Button variant="primary"
                              type={'submit'}
                              className={`${style.findBtn}`}
                      >
                        Find a location<img alt={'chevron right'} src={chevronRight} width={11} height={16} className={'ml-5'} />
                      </Button>
                      :
                      <Button variant="primary"
                              className={`${style.findBtn}`}
                              type={'reset'}
                      >
                        Reset<img alt={'reset'} src={refreshIcon} width={17} height={16} className={'ml-3'} />
                      </Button>
                    }
                  </div>
                </Form>
              )
              // : null
            }

          </Container>
        </section>
      </BackgroundImage>
    </div>
      </>
  )
}




