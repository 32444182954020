
import { graphql } from "gatsby"
import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from './PolicyDetail.module.scss';
import "font-awesome/css/font-awesome.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { faHeart } from "@fortawesome/free-regular-svg-icons"

import help from "../../images/help.png"
import { StickyHelp } from "../UI/StickyHelp";
export const fragment = graphql`
  fragment policyDetailFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_PolicyDetail {
    description
    sectionHeader
    objects {
      objectTitle
      middleTitle
      objectDescription
      objectItems {
        itemText
      }
    }
  }
`
export const PolicyDetail = ({sectionHeader,description,objects}) => {
  return (
    <div className="position-relative">
    <StickyHelp />
    <section className={`px-3 position-relative ${style.section}`}>

      <a href="/policies" className={style.allNews}>
        <FontAwesomeIcon icon={faChevronLeft} className={style.chevronIcon} />
        Back to all policies
      </a>
      <h1 className="mb-4 mt-3 h2 text-left" dangerouslySetInnerHTML={{ __html: sectionHeader }} />
      <h5  className={`mb-4 text-left ${style.descriptionTitle}`} dangerouslySetInnerHTML={{ __html: description }} />
      <Row >
        {
          objects.map((item, i) => {
            return (
              <Col sm={12} >
                <div  className="mb-5" key={i}>
                  {item.objectTitle && <h5 className={` text-left ${style.descriptionTitle}`} dangerouslySetInnerHTML={{ __html:item.objectTitle }} />}
                  {item.objectDescription && <div className={` text-left ${style.descriptionText}`} dangerouslySetInnerHTML={{ __html: item.objectDescription }} />}
                  {item.middleTitle && <h5 className={` text-left ${style.middleHeader}`} dangerouslySetInnerHTML={{ __html: item.middleTitle }} />}

                  <ul className={style.list}>
                    {item.objectItems && item.objectItems.map((product, i) => (
                      <li key={i}>
                        <h5  className={` font-weight-normal text-justify ${style.itemDescription}`} dangerouslySetInnerHTML={{ __html: product.itemText }} />
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            )
          })
        }
      </Row>
    </section>
      </div>
  )
}

