import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { createLink } from "../../utils"
import Image from "gatsby-image"
import { Button } from "react-bootstrap"

export const fragment = graphql`
  fragment instagramFeedFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_InstagramFeed {
    sectionHeader
    button {
      title
      url
    }
    buttonIcon {
      ...GatsbyImageQuery
    }
  }
`

  const Feed_QUERY = graphql`
    query myQuery {
      allInstagramContent(limit: 12) {
        edges {
          node {
            media_url
            localImage {
              childImageSharp {
                fluid(maxHeight: 500, maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `

  export const InstagramFeed = ({ sectionHeader, button, buttonIcon }) => {
    const data = useStaticQuery(Feed_QUERY)
    let { edges } = data.allInstagramContent

    return (
      <div>
        <Container fluid={true}>
          <h2
            className="mt-7 mb-6"
            dangerouslySetInnerHTML={{
              __html: sectionHeader,
            }}
          />
          <Row className="mb-3 rowContainer">
            {edges.map((item, i) =>
              item.node.localImage.childImageSharp ? (
                <Col
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                  className="d-flex justify-content-center mb-3"
                  key={i}
                >
                  <div>
                    <a
                      href={item.node.media_url}
                      target="_blank"
                      rel="noopener"
                      tabIndex="0"
                    >
                      <Image
                        fluid={item.node.localImage.childImageSharp.fluid}
                        className="igImg"
                      />
                    </a>
                  </div>
                </Col>
              ) : (
                <div></div>
              )
            )}
          </Row>
          <div className="d-flex justify-content-center ">
            {button && (
              <Button
                href={createLink(button.url)}
                variant="secondary"
                size="lg"
                target="_blank"
                rel="noopener noreferrer"
                className="mb-8 mb-lg-5"
              >
                {button.title}{" "}
                <img
                  src={buttonIcon.sourceUrl}
                  alt="right arrow"
                  className="rightArrow"
                />
              </Button>
            )}
          </div>
        </Container>
      </div>
    )
  }
