import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import LatestPosts from "../UI/LatestPosts"

export const fragment = graphql`
  fragment latestNewsFragment on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_LatestNews {
    sectionHeader
    category
    buttonLink {
      title
      url
    }
    backgroundImage {
      ...GatsbyImageQuery
    }
  }
`

export const LatestNewsComp = ({ sectionHeader, buttonLink, category }) => {
  return (
    <section className="bg-light mb-lg-0">
      <Container fluid={true}>
        <LatestPosts
          sectionHeader={sectionHeader}
          buttonLink={buttonLink.url}
          buttonText={buttonLink.title}
          category={category}
        />
      </Container>
    </section>
  )
}
